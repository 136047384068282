.scrollbar {
  /* stylelint-disable-next-line */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /* stylelint-disable-next-line */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* stylelint-disable-next-line */
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: transparent;
  }

  &:hover {
    /* stylelint-disable-next-line */
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;

      @apply bg-gradient-to-b from-primary-50 to-primary-100;
    }
  }
}
