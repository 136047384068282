// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

@use '@angular/material' as mat;

// Import all helpers
@import './scss/helpers/index';

// Rithm light Theme
@import './scss/themes/index';

// Tailwind Imports
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/// Import Tools from tools file
/// Contains global styles for circles and typography
/// This import needs to stay after the tailwind imports.
@import './scss/tools/index';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
// @include mat.all-component-typographies($custom-typography);
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rithm-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
/* stylelint-disable */
$rithm-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
/* stylelint-enable */

// The warn palette is optional (defaults to red).
$rithm-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($rithm-theme);

// Apply Rithm light theme
@include mat.all-component-themes($rithm-light);

// Global Styles Below
/// Global page Styles
* {
  margin: 0;
  padding: 0;
}

body {
  background: $primary-blue;
  overflow: hidden;
}

// Style for the snackbar used in the map.
.snackbar-map {
  color: #dfe1e3;
  background-color: $secondary-black;

  .mdc-snackbar__surface {
    min-width: fit-content !important;
  }
}

.textured-bg {
  background-image: url('./assets/images/patterns/dust_scratches.png');
  background-repeat: repeat;
}

/// Styling of links globally
a {
  color: $accent-blue;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

// Cursors for the map
.addStationGroupCursor {
  cursor: url('./assets/images/cursors/addStationGroup.png'), auto !important;
}

.addStationCursor {
  cursor: url('./assets/images/cursors/newAddStation.png'), auto !important;
}

.mapLoadingCursor {
  cursor: url('./assets/images/cursors/loading_cursor-only.png'), auto !important;
}

.mapLoadingCursorSpinner {
  position: absolute;
}

.horizontalResizingCursor {
  cursor:
    url('./assets/images/cursors/horizontalResizing.png') 15 15,
    auto !important;
}

.verticalResizingCursor {
  cursor:
    url('./assets/images/cursors/verticalResizing.png') 15 15,
    auto !important;
}

.mainDiagonalResizingCursor {
  cursor:
    url('./assets/images/cursors/mainDiagonalResizing.png') 15 15,
    auto !important;
}

.secondaryDiagonalResizingCursor {
  /* stylelint-disable-next-line declaration-colon-newline-after */
  cursor:
    url('./assets/images/cursors/secondaryDiagonalResizing.png') 15 15,
    auto !important;
}

.cursorCopy {
  cursor: url('./assets/images/cursors/copy.png'), auto !important;
}

// Style for the selection box used in the map.
.selectBox {
  position: fixed;
  border: 1px solid #1b4387;
  background-color: rgba(123, 163, 237, 12%);
}

// Style for the station popup and stationGroup of the map.
.card-popup-station {
  @apply fixed hidden bg-white w-[360px] min-h-[130px] p-3.5 pt-4 rounded-md drop-shadow-lg opacity-0;

  .card-header {
    .popup-title {
      @apply font-bold mb-1 truncate;

      /* stylelint-disable-next-line */
      color: map-get($primary-palette, 500);
    }

    .update-date {
      @apply flex items-center;

      /* stylelint-disable-next-line */
      color: map-get($primary-palette, 300);

      p {
        @apply text-xs font-[400] mb-1;
      }
    }

    .containers-number {
      @apply mb-1 text-accent-500;

      &:hover {
        @apply underline;
      }
    }
  }

  .card-buttons {
    @apply flex gap-3;
  }

  .card-buttons-view {
    @apply mt-4 space-x-2;
  }

  .station-name-field {
    @apply w-full;

    .mat-mdc-form-field-flex {
      @apply h-12;
    }
  }

  .popup-button {
    @apply flex justify-center items-center w-[40px] h-[40px] border border-primary-50 rounded-[10px];

    .icon {
      @apply text-gray-700;
    }

    &:hover:not(.resizing-button-selected) {
      /* stylelint-disable-next-line */
      background-color: rgba(map-get($accent-palette, 'A100'), $alpha: 0.2);
    }

    &:active:not(.resizing-button-selected) {
      /* stylelint-disable-next-line */
      background-color: rgba(map-get($accent-palette, 'A100'), $alpha: 0.4);
    }
  }

  .popup-button-delete {
    .icon {
      color: $warning-red;
    }
  }
}

.bg-ribbon {
  /* stylelint-disable-next-line */
  background: linear-gradient(
    180deg,
    rgb(31, 41, 55) 35%,
    rgb(229, 229, 229) 0%
  );

  @apply min-h-[560px];
}

.dark {
  .bg-ribbon {
    background: rgb(31, 41, 55) !important;
  }
}

// Global map styles
.map-zoom-option-panel {
  position: relative !important;
  left: -156px;
  min-width: 256px;
  max-height: calc(100vh - 110px) !important;
}

// Delete border mat-chips
/* stylelint-disable-next-line */
.mdc-evolution-chip__action--primary::before {
  border: none !important;
}

.counter-square {
  @apply font-medium bg-secondary-200 text-white rounded-sm flex items-center justify-center w-5 h-5 flex-shrink-0;
}

// * INPUTS
/// This will set the styles for all inputs as needed on the project.
/// Also will prevent labels from being cut off.
.mat-mdc-form-field {
  &:not(.dashboard-name-input-field) {
    @apply bg-white;
  }

  &.short-text {
    .mat-mdc-form-field-flex {
      @apply items-center;

      height: 50px;
    }
  }

  &.prefixed-icon-form {
    @apply mb-3;

    .mdc-text-field--outlined {
      .mdc-notched-outline__notch {
        .mdc-floating-label:not(.mdc-floating-label--float-above) {
          @apply text-base;

          font-size: 1.25rem;

          /* stylelint-disable-next-line */
          transform: scale(0.8, 0.8) translateY(-70%) translateX(34px) !important;
        }
      }
    }
  }
}

.ngxp__container > .ngxp__arrow {
  background-color: #fff !important;
}

.map-element-width {
  max-width: calc(100% - 66px);
}

.map-tree-node {
  @apply relative;

  width: calc(100% - 30px);
}

.library-component-icon {
  @apply flex justify-center items-center w-8 h-8 bg-gray-200 rounded-md;
}

.tooltip-function-settings {
  width: max-content;
}

.custom-select {
  .mat-mdc-optgroup-label,
  .mat-mdc-option {
    @apply border-b border-gray-200 pl-6 font-medium h-[56px];
  }

  /* stylelint-disable */
  .mat-mdc-optgroup-label .mdc-list-item__primary-text,
  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
    color: #9ca3af !important;
  }
}

.custom-select.last-select {
  .mat-mdc-option:last-child {
    @apply border-0;
  }
}

.action-chips {
  .action-title {
    @apply text-base text-gray-800 font-medium leading-6;
  }

  .sub-section-title {
    @apply text-gray-600 text-xs font-medium leading-[18px] mb-1;
  }

  .icon-chip {
    @apply text-gray-600 pr-1;
  }

  .label-chip {
    @apply text-gray-800 text-sm font-medium;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    @apply bg-gray-200;
  }
}

.generic-menu {
  .mat-mdc-menu-content {
    padding: 0 !important;
  }

  .generic-menu-item {
    @apply w-full h-[56px] px-3 text-left border-b border-gray-200;

    &:hover {
      @apply bg-gray-50;
    }

    .item-label {
      @apply text-gray-800 text-sm font-medium;

      &.error {
        @apply text-red-500;
      }
    }
  }
}
